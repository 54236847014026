import React, { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router } from 'react-router-dom';
import SiteRoutes from './Routes';
import * as routePaths from './RoutePaths';
import Spinner from './view/pages/spinner/Spinner';
import { Domains } from './enums';

let timer: NodeJS.Timeout;

const useBronson = () => {
  const [status, setStatus] = useState<'initial' | 'error' | 'success'>('initial');
  useEffect(() => {
    timer = setInterval(() => {
      const { bronson } = window as any;

      if (!!bronson === false) return;

      setStatus('success');
    }, 200);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (status !== 'initial') {
      clearInterval(timer);
    }
  }, [status]);

  return { status };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types

function App() {
  const { status } = useBronson();

  let brand = 'Audi';
  if (
    window?.location?.pathname.match(routePaths?.VW) ||
    window?.location?.pathname.match(routePaths?.VW_CANADA) ||
    Domains.VW.includes(window?.location?.host)
  ) {
    brand = 'VW6';
  } else if (window?.location?.pathname.match(routePaths?.AUDI) || Domains.AUDI.includes(window?.location?.host)) {
    brand = 'Audi';
  } else {
    brand = 'Audi';
  }
  const jsurl = `https://cdn.bronson.vwfs.io/${brand.toLowerCase()}/v/11.6.0/js/bronson.min.js`;
  const cssurl = `https://cdn.bronson.vwfs.io/${brand.toLowerCase()}/v/11.6.0/css/style.min.css`;
  const appcssurl = `${window?.location?.origin}/styles/${brand.toLowerCase()}.css`;
  const iconurl =
    `${brand.toLowerCase()}` === 'audi'
      ? 'https://cdn.bronson.vwfs.io/audi/v/15.7.1/img/logo.svg'
      : 'https://cdn.bronson.vwfs.io/vw6/v/15.7.1/img/logo.svg';
  return (
    <div className='App'>
      <HelmetProvider>
        <Helmet>
          <script type='text/javascript' src={jsurl} />
          <link rel='stylesheet' href={cssurl} />
          <link rel='stylesheet' href={appcssurl} type='text/css' />
          <link rel='icon' href={iconurl} />
          <meta name='viewport' content='width=device-width, initial-scale=1' />
          <meta name='description' content='Terms and conditions page' />
        </Helmet>
      </HelmetProvider>
      {status !== 'initial' ? (
        <Router>
          <SiteRoutes />
        </Router>
      ) : (
        <Spinner ariaLabel spinnerType='full-page' />
      )}
    </div>
  );
}

export default App;
