import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import englishUSA from './en-us.json';
import frenchCAN from './fr-ca.json';
import englishCA from './en-ca.json';

// the translations
const resourceContent = {
  enUS: {
    translation: englishUSA,
  },
  enCA: {
    translation: englishCA,
  },
  frCA: {
    translation: frenchCAN,
  },
};

i18n.use(LanguageDetector).use(initReactI18next).init({
  fallbackLng: 'enUS',
  resources: resourceContent,
  returnNull: false,
});

// you can test different languages by using query param ex: http://localhost:3000/tnc/vw?lng=frCA
i18n.changeLanguage();

export default i18n;
